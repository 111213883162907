export function getModal(agencyId, locationId, notificationId) {
  $.ajax({
    url: `/agencies/${agencyId}/${locationId}/notifications/${notificationId}`,
    type: 'GET',
    success: function(response) {
      if (response.data){
        $('body').append(response.data.html);
        let notificationId = response.data.id;
        var anchor = $(`#dropdownMenu a[data-notification-id="${notificationId}"]`);
        if (anchor.length) {
            anchor.removeClass('font-semibold');
        }
        var redDot = $('#red_dot');
        if (response.unseen_notifications){
          redDot.removeClass('hidden');
        } else {
          redDot.addClass('hidden');
        }
      }
    },
    error: function(xhr, status, error) {
      console.error('Error loading notification:', error);
    }
  });
}

export function update_custom_variables(agencyId, locationId){
  const formElement = $('#customvariableForm');
    const allInputs = formElement.find('input[required]');
    const allFilled = allInputs.toArray().every(input => $(input).val().trim() !== '');

    if (!allFilled) {
      alert('Please fill all the required fields.');
      return;
    }

    const customVariableDivs = formElement.find('.custom_variable_div');

    const templateData = {};

    customVariableDivs.each(function() {
      const div = $(this);
      const inputs = div.find('input');

      inputs.each(function() {
        const input = $(this);
        if (input.attr('name') === "templateId") {
          templateData.templateId = input.val();
        } else if (input.attr('name') === "campaignId") {
          templateData.campaignId = input.val();
        } else {
          templateData[input.attr('name')] = input.val();
        }
      });
    });
    $('#message_modal').remove();

    $.ajax({
      url: `/agencies/${agencyId}/${locationId}/clients/update_custom_variable`,
      data: { template_data: templateData },
      method: 'GET',
      success: function(response) {
        
      },
      error: function(error) {
        // Handle error
        console.error('Error updating custom variables:', error);
      }
    });
  
}
