import consumer from "./consumer"
import { extractLocationIdFromUrl } from "../utils/agency_utils"
import { getModal, update_custom_variables } from "../utils/ajax_utils"

const externalId = extractLocationIdFromUrl();
let notificationId = '';
let locationId = '';
let agencyIdFromJob = '';

consumer.subscriptions.create({
  channel: "ClientChannel",
  location_id: externalId
}, {
  connected() {
    console.log('Connected to ClientChannel');
  },

  disconnected() {
    console.log('Disconnected from ClientChannel');
  },

  received(data) {
    
    console.log('Received data ClientChannel:', data);
    notificationId = data.notification_id;
    if (data.location_id && data.agency_id) {
      locationId = data.location_id;
      agencyIdFromJob = data.agency_id;
    }

    const modal = `
    <div style="border-top-color: #3B82F6; background: #EBF8FE;" id="notification" class="z-10 notification bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md fixed top-4 right-4 max-w-md" role="alert">
      <div class="flex">
        <div class="py-1">
          <svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" style="color: #3B82F6;">
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
          </svg>
        </div>
        <div class="flex-1">
          <p class="font-bold">${data.campaign_name}</p>
          <p class="text-sm">Would you like to push custom variable changes to the campaign?</p>
          <div class="mt-2">
            <button class="bg-blue-500 text-white px-4 py-1 rounded mr-2" onclick="handleYes()">Yes</button>
            <button class="bg-gray-500 text-white px-4 py-1 rounded" onclick="closeNotification()">No</button>
          </div>
        </div>
        <button class="close-button text-teal-500 hover:text-teal-700 ml-4 focus:outline-none" onclick="closeNotification()">×</button>
      </div>
    </div>
  `;

  $('body').append(modal);
  var firstAnchor = $('#dropdownMenu a:first');
  var redDot = $('#red_dot');
  if (redDot.hasClass('hidden')) {
    redDot.removeClass('hidden');
  }
  if (firstAnchor.length) {
      var newAnchor = firstAnchor.clone();
      if (!newAnchor.hasClass('font-semibold')) {
        newAnchor.addClass('font-semibold');
      }
      newAnchor.attr('data-notification-id', notificationId);
      newAnchor.data('notification-id', notificationId);
      newAnchor.text(`Click on this to push changes to the campaign ${data.campaign_name}`);
      newAnchor.insertBefore(firstAnchor);
      
  } else {
    var dropdown = $('#dropdownMenu');
    var newAnchor = $(`<a href="" class="mb-1 block px-4 py-2 text-sm text-gray-700 font-semibold" role="menuitem" tabindex="-1" data-notification-id=${notificationId}>Click on this to push changes to the campaign ${data.campaign_name}</a>`);
    var secondParagraph = dropdown.find('p').eq(1);
    if (secondParagraph.length) {
        secondParagraph.append(newAnchor);
    } else {
        var newParagraph = $('<p></p>').append(newAnchor);
        dropdown.append(newParagraph);
    }
  }
}
});

function closeNotification() {
  const notification = $('#notification');
  if (notification.length) {
    notification.remove();
  }
}
function handleYes() {
  closeNotification();
  getModal(agencyIdFromJob, locationId, notificationId);

  $('#submit_btn').on('click', function() {
    const location = window.location;
    const segments = location.pathname.split('/').filter(Boolean);
    if (segments.length >= 2) {
      const agencyId = segments[1];
      const locationId = segments[2];
      update_custom_variables(agencyId, locationId);
    }
  });

  $('#close_btn').on('click', function() {
    $('#message_modal').remove();
  });
}     
window.closeNotification = closeNotification;
window.handleYes = handleYes;