// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

document.addEventListener('DOMContentLoaded', () => {
  const path = window.location.pathname;
  const channels = require.context('.', true, /_channel\.js$/);
  
  if (path.startsWith('/agencies/')) {
    channels.keys().filter(file => file == './client_channel.js').forEach(channels);
  }else{
    channels.keys().filter(file => file !== './client_channel.js').forEach(channels);
  }
});
