export function extractLocationIdFromUrl() {
  const url = window.location.href;
  const segments = url.split('/');
  const agenciesIndex = segments.indexOf('agencies');
  
  if (agenciesIndex !== -1 && agenciesIndex + 2 < segments.length) {
    return segments[agenciesIndex + 2];
  } else {
    return null;
  }
}
